<script>
import { apiGetBanner } from "@/api/home";
import { apiGetCourses } from "@/api/courses";
import GlobalCourseCard from "@/components/global/GlobalCourseCard";
import Swiper from "swiper";
import * as dayjs from "dayjs";

export default {
    components: { GlobalCourseCard },
    data() {
        return {
            guideGroup: [
                {
                    title: "<h2><span class='<md:hidden'>最完整分級</span>系統</h2>",
                    image: require("@/assets/images/courses-guide-1.png"),
                },
                {
                    title: "<h2>頂尖<span class='<md:hidden'>師資顧問群</span></h2>",
                    image: require("@/assets/images/courses-guide-2.png"),
                },
                {
                    title: "<h2>菁英<span class='<md:hidden'>小班制</span></h2>",
                    image: require("@/assets/images/courses-guide-3.png"),
                },
            ],
            qrCodeImages: [
                {
                    title: "名門留學官方帳號",
                    image: require("@/assets/images/qr-code-primerica.png"),
                },
            ],
            kind: [
                {
                    kindid: "1180",
                    kindtitle: "新制TOEFL iBT",
                },
                {
                    kindid: "1181",
                    kindtitle: "SAT/ ACT",
                },
                {
                    kindid: "1183",
                    kindtitle: "SSAT/ISEE",
                },
                {
                    kindid: "1011",
                    kindtitle: "AP",
                },
            ],
            courses: {
                data: [],
                meta: {},
            },
            tabBar: {
                width: "120px",
                offset: "120px",
            },
            coupons: [
                require("@/assets/images/coupon-1.png"),
                require("@/assets/images/coupon-2.png"),
                require("@/assets/images/coupon-3.png"),
                // require("@/assets/images/coupon-4.png"),
            ],
            tabBarStyle: {},
            course: {
                memo: "",
                title: "",
                ctimeart: null,
                ctime: null,
            },
            coursesTab: null,
            time: null,
            loading: false,
            banner: "",
        };
    },

    computed: {
        tabsFilter() {
            const showTabs = [1180, 1181, 1011, 1183];
            return this.kind.filter((tab) => {
                return showTabs.includes(parseInt(tab.kindid));
            });
        },
        openDes() {
            return this.course.memo.length > 0;
        },
        courseTime() {
            if (this.course.ctimeart === null) return "";
            return this.course.ctimeart.split("[")[1].split("]")[0];
        },
        courseDate() {
            if (this.course.ctimeart === null) return "";
            return this.course.ctimeart.split("[")[0];
        },
    },
    methods: {
        focusTab(id) {
            return this.$route.query.kind === id;
        },
        async getCourses(kindid) {
            if (this.$route.query.kindid !== kindid) {
                this.loading = true;
                this.$router.push({
                    name: "Courses",
                    query: { kindid: kindid },
                });
                const { data } = await apiGetCourses({ kindid });
                this.courses.data = data.data.sort((a, b) => {
                    return dayjs(a.createdate).$d.getTime() - dayjs(b.createdate).$d.getTime();
                });

                this.loading = false;
            }
        },
        openDescription(course) {
            if (course.memo.length > 0) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
            this.course.memo = course.memo;
            this.course.title = course.title;
            this.course.ctimeart = course.ctimeart;
            this.course.ctime = course.ctime;
        },
        courseTabSwiper() {
            this.coursesTab = new Swiper(".mySwiper-v2", {
                slidesPerView: 2,
                spaceBetween: "8",
                breakpoints: {
                    310: {
                        slidesPerView: 2,
                        spaceBetween: "8",
                    },
                    640: {
                        slidesPerView: 4,
                        spaceBetween: "12",
                    },
                },
            });
        },
    },
    async mounted() {
        const { data: banners } = await apiGetBanner();
        this.banner = banners.data[0].img;
        //
        // const { data: kindData } = await apiGetCourseKind();
        // this.kind = kindData.data;
        this.getCourses(1180);
        // const { data } = await apiGetCourses({ kindid: "1180" });
        //
        this.$nextTick(() => {
            this.courseTabSwiper();
        });
    },
};
</script>
<template>
    <div>
        <div class="bner-infor-bg relative">
            <div class="h-full bg-center bg-cover" :style="{ 'background-image': `url(${banner})` }"></div>
        </div>
        <div class="space-x-120px <md:space-x-30px flex items-center max-w-1145px mx-auto py-60px <md:py-40px <sm:py-28px px-20px flex justify-center items-center <md:w-8/10">
            <div v-for="guide in guideGroup" :key="guide.title">
                <div class="text-center text-gray-3 text-20px font-semibold <md:text-18px <sm:text-16px mb-40px <md:mb-24px <sm:mb-12px guide-title " v-html="guide.title"></div>
                <img :src="guide.image" alt="" width="200px" class="mx-auto" />
            </div>
        </div>
        <div class="max-w-1065px px-20px mx-auto">
            <div class="swiper-container overflow-hidden mySwiper-v2 relative border-b-1 border-solid border-[#C4C4C4] mb-24px">
                <!-- :class="[`course-tab-${item.kindid}`, { active: focusTab(item.kindid) }]" -->
                <ul class="swiper-wrapper">
                    <li
                        v-for="(item, idx) in tabsFilter"
                        :key="item.kindid"
                        :class="[idx === 0 ? 'bg-[#F2BA1F]' : '', idx === 1 ? 'bg-[#F08C53]' : '', idx === 2 ? 'bg-[#4380BB]' : '', idx === 3 ? 'bg-[#6A76BE]' : '']"
                        class="swiper-slide font-semibold bg-opacity-60 cursor-pointer text-[#454545] text-center px-12px py-12px bg-center bg-cover rounded-t-4px w-auth inline <md:text-18px text-22px <md:min-w-100px"
                        @click="getCourses(item.kindid)"
                    >
                        {{ item.kindtitle }}
                    </li>
                </ul>
            </div>
        </div>
        <section class="max-w-1065px mr-auto ml-auto px-20px min-h-400px">
            <div v-if="loading" class="text-center py-30px">
                loading...
            </div>
            <div v-else class="grid grid-cols-3 gap-48px <lg:gap-20px mb-70px <md:grid-cols-2 <sm:grid-cols-1">
                <GlobalCourseCard v-for="course in courses.data" :key="course.id" :course="course" class="mx-auto" @open-description="openDescription" />
            </div>
        </section>
        <section class="mb-40px max-w-1065px mx-auto px-20px ">
            <div class="flex items-center justify-center flex-wrap">
                <img v-for="(coupon, index) in coupons" :key="index" class="w-1/4 max-w-250px <md:w-1/2 <sm:w-full" :src="coupon" alt="" />
            </div>
            <ul class="mx-20px pl-32px pr-12px py-6px bg-yellow-200 border-1 border-solid border-yellow-400 rounded-lg text-sm relative">
                <i class="fas fa-info-circle text-lg text-yellow-500 absolute left-6px top-2px"></i>
                <li class="text-yellow-800">早鳥優惠不可與單科特殊優惠合併使用，<strong>早鳥優惠說明: 開課一個月前完成報名</strong></li>
            </ul>
            <div></div>
        </section>
        <section class="min-h-676px py-120px <md:py-60px" style="background-color:#F5F6F8">
            <div class="mx-auto max-w-820px px-20px space-y-60px <sm:space-y-24px">
                <div class="flex <md:block items-center font-semibold text-gray-3 justify-center ">
                    <p class="text-28px mr-5 <md:text-center <md:mb-12px <sm:text-20px <sm:mr-0">
                        Contact Us!
                    </p>
                    <p class="text-20px <md:text-center <sm:leading-6 <sm:text-16px">
                        名門系列課程 提升孩子實力的最好選擇！
                    </p>
                </div>
                <div class="flex justify-center <sm:flex-wrap items-center space-x-84px mx-auto <lg:w-8/10 <lg:space-x-40px <sm:space-x-0">
                    <div v-for="(code, idx) in qrCodeImages" :key="idx" class="<sm:w-1/2 <sm:last:mt-20px">
                        <img :src="code.image" alt="" class="mb-5" />
                        <p class="text-center text-18px font-semibold text-gray-3 <sm:text-16px">
                            {{ code.title }}
                        </p>
                    </div>
                </div>
                <div>
                    <p class="text-gray-3 text-center <sm:text-sm">
                        *名門保有修改課表之權利* <br />
                        名門留遊學教育中心 | (02) 2751-3624<br />
                        台北市大安區忠孝東路四段155號4樓之2
                    </p>
                </div>
            </div>
        </section>
        <div
            @click.self="
                openDescription({
                    memo: '',
                    title: '',
                    ctimeart: null,
                    ctime: null,
                })
            "
            class="over-layout flex justify-center items-end transition duration-500"
            :class="[openDes ? 'opacity-100 pointer-events-auto ' : 'opacity-0 pointer-events-none']"
        >
            <div class="bg-yellow-300 w-full rounded-t-20px transform transition duration-500" :class="[openDes ? 'translate-y-0' : 'translate-y-full']">
                <div class="max-w-700px mx-auto py-60px pb-45px relative">
                    <i
                        class="far fa-times-circle absolute right-12px top-12px cursor-pointer text-gray-600 text-30px"
                        @click="
                            openDescription({
                                memo: '',
                                title: '',
                                ctimeart: null,
                                ctime: null,
                            })
                        "
                    ></i>
                    <h2 class="text-xl text-[#005594] font-semibold mb-24px">
                        {{ course.title }}
                    </h2>
                    <div class="mb-12px text-[#003467]">
                        <p>日期：{{ courseDate }}</p>
                        <p>時間：{{ courseTime }}</p>
                        <p>上課日：{{ course.ctime }}</p>
                    </div>
                    <p class="text-[#5F6265]" v-html="course.memo"></p>
                </div>
            </div>
        </div>
    </div>
</template>
<style src="@/assets/css/banner.css"></style>
<style lang="postcss" scoped>
.guide-title {
    span {
        @apply hidden;
    }
}
</style>
