import axios from "@/plugins/axios";

// 取得課程
export const apiGetCourses = (payload) => {
  return axios.get("/api/board.php", { params: { kind: "courses", kindid: payload.kindid } });
};
// 取得課程種類
export const apiGetCourseKind = () => {
  return axios.get("/api/kind.php?kind=courseskind");
};
