<script>
export default {
  props: {
    course: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    openDescription() {
      this.$emit("open-description", this.course);
    },
  },
  computed: {
    courseTime() {
      if (this.course.ctimeart === null) return "";
      return this.course.ctimeart.split("[")[1].split("]")[0];
    },
    courseDate() {
      if (this.course.ctimeart === null) return "";
      return this.course.ctimeart.split("[")[0];
    },
    cardStyle() {
      let color = "";
      if (this.$route.query.kindid === "1180") color = "#F2BA1F";
      if (this.$route.query.kindid === "1181") color = "#F08C53";
      if (this.$route.query.kindid === "1183") color = "#4380BB";
      if (this.$route.query.kindid === "1011") color = "#6A76BE";
      return { "background-color": color };
    },
  },
};
</script>
<template>
  <div
    class="border-[#e1e1e1] border-1 border-solid rounded-b-8px w-full max-w-308px relative shadow-md cursor-pointer transition duration-500 hover:shadow-xl"
    @click="openDescription"
  >
    <div class="flex items-center justify-center overflow-hidden border-b-1 border-solid border-[#eee]">
      <div style="padding-top:60%" class="w-full relative">
        <div
          class="h-full w-full transform hover:scale-120 transition-transform duration-300 absolute left-0 top-0"
          :style="{ background: `url(${course.img})`, backgroundSize: 'cover' }"
        ></div>
      </div>
    </div>
    <div class="py-15px px-28px">
      <p class="text-[#E38C26] text-orange-1 leading-6 text-lg font-semibold pb-8px border-b-1 min-h-56px border-solid border-red-[#d3d3d3]">
        {{ course.title }}
      </p>
      <p class="text-[#454545] leading-6 mt-8px mb-4px font-medium line-clamp-2 min-h-48px">
        {{ course.subtitle }}
      </p>
      <div class="space-y-1">
        <p class="text-gray-2 mr-4px text-[#898A8B]">日期：{{ courseDate }}</p>
        <p class="text-gray-2 mr-4px text-[#898A8B]">時間：{{ courseTime }}</p>
        <p class="text-gray-2 line-clamp-1 text-[#898A8B]">上課日：{{ course.ctime }}</p>
      </div>
    </div>
    <div :style="cardStyle" class="h-8px w-full bg-yellow-400 absolute bottom-[-1px] rounded-b-8px"></div>
  </div>
</template>
